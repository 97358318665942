module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/__w/1/s/sites/spain","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images-datocms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../themes/gatsby-theme-multi-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
