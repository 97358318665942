// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-nuestra-historia-de-calidad-tsx": () => import("./../../../src/pages/nuestra-historia-de-calidad.tsx" /* webpackChunkName: "component---src-pages-nuestra-historia-de-calidad-tsx" */),
  "component---src-pages-quienes-somos-tsx": () => import("./../../../src/pages/quienes-somos.tsx" /* webpackChunkName: "component---src-pages-quienes-somos-tsx" */),
  "component---themes-gatsby-theme-multi-site-src-pages-404-tsx": () => import("./../../../../../themes/gatsby-theme-multi-site/src/pages/404.tsx" /* webpackChunkName: "component---themes-gatsby-theme-multi-site-src-pages-404-tsx" */),
  "component---themes-gatsby-theme-multi-site-src-pages-about-us-tsx": () => import("./../../../../../themes/gatsby-theme-multi-site/src/pages/about-us.tsx" /* webpackChunkName: "component---themes-gatsby-theme-multi-site-src-pages-about-us-tsx" */),
  "component---themes-gatsby-theme-multi-site-src-pages-contact-us-tsx": () => import("./../../../../../themes/gatsby-theme-multi-site/src/pages/contact-us.tsx" /* webpackChunkName: "component---themes-gatsby-theme-multi-site-src-pages-contact-us-tsx" */),
  "component---themes-gatsby-theme-multi-site-src-pages-index-tsx": () => import("./../../../../../themes/gatsby-theme-multi-site/src/pages/index.tsx" /* webpackChunkName: "component---themes-gatsby-theme-multi-site-src-pages-index-tsx" */),
  "component---themes-gatsby-theme-multi-site-src-pages-menu-tsx": () => import("./../../../../../themes/gatsby-theme-multi-site/src/pages/menu.tsx" /* webpackChunkName: "component---themes-gatsby-theme-multi-site-src-pages-menu-tsx" */),
  "component---themes-gatsby-theme-multi-site-src-templates-information-information-tsx": () => import("./../../../../../themes/gatsby-theme-multi-site/src/templates/Information/Information.tsx" /* webpackChunkName: "component---themes-gatsby-theme-multi-site-src-templates-information-information-tsx" */),
  "component---themes-gatsby-theme-multi-site-src-templates-legal-legal-tsx": () => import("./../../../../../themes/gatsby-theme-multi-site/src/templates/Legal/Legal.tsx" /* webpackChunkName: "component---themes-gatsby-theme-multi-site-src-templates-legal-legal-tsx" */),
  "component---themes-gatsby-theme-multi-site-src-templates-store-details-store-details-tsx": () => import("./../../../../../themes/gatsby-theme-multi-site/src/templates/StoreDetails/StoreDetails.tsx" /* webpackChunkName: "component---themes-gatsby-theme-multi-site-src-templates-store-details-store-details-tsx" */)
}

